const meta = {
    title: 'Jak pompować opony rowerowe',
    date: '2024-11-21',
    description: 'Dowiedz się, jak poprawnie napompować opony rowerowe, aby zapewnić płynną i bezpieczną jazdę.',
    author: 'Gearro Team',
    bannerImage: 'https://nextvelo-prod.b-cdn.net/blog_pictures/Screenshot%202024-11-21%20at%2020.29.10.png',
};

const content = `
<article>
    <h2>Dlaczego prawidłowo napompowane opony są ważne?</h2>
    <p>Prawidłowo napompowane opony są przepustką do płynnej, bezpiecznej jazdy, niezależnie od tego, czy chodzi o dojazdy do pracy, pokonywanie szlaków, czy poruszanie się po ulicach miasta. Na szczęście pompowanie opon rowerowych jest dość proste, jeśli tylko wiesz, jak to zrobić.</p>
  <img src="https://nextvelo-prod.b-cdn.net/blog_pictures/Screenshot%202024-11-21%20at%2020.29.10.png" alt="Jak pompować opony rowerowe: Krótki przewodnik dla każdego rowerzysty" />
    
    <h3>Krok 1: Określ typ zaworu - Presta czy Schrader?</h3>
    <p>Najpierw określ, jaki rodzaj zaworu ma Twój rower. Opony rowerowe mogą mieć:</p>
    <ul>
        <li><strong>Zawór Presta:</strong> Węższy, wysokociśnieniowy zawór, stosowany głównie w rowerach szosowych.</li>
        <li><strong>Zawór Schrader:</strong> Szerszy, podobny do samochodowego, spotykany w rowerach górskich i hybrydowych.</li>
    </ul>
    <p>Wybierz odpowiednie mocowanie pompki, aby uniknąć wycieków powietrza.</p>
    <h3>Krok 2: Sprawdź zalecane ciśnienie w oponie</h3>
    <p>Każda opona ma określony zakres ciśnienia na ścianie bocznej, oznaczony jednostką PSI. Przykłady:</p>
    <ul>
        <li><strong>Rowery szosowe:</strong> 80-130 PSI dla jazdy po gładkich nawierzchniach.</li>
        <li><strong>Rowery górskie:</strong> 30-50 PSI dla większej przyczepności w trudnym terenie.</li>
    </ul>
    <p>Utrzymuj ciśnienie w odpowiednim zakresie, aby zmniejszyć ryzyko przebicia opony.</p>
    <h3>Krok 3: Prawidłowe pompowanie i mocowanie</h3>
    <p>Wybierz odpowiednią pompkę:</p>
    <ul>
        <li><strong>Pompki podłogowe:</strong> Wydajne i stabilne do użytku domowego.</li>
        <li><strong>Mini pompki:</strong> Kompaktowe i idealne do użytku w podróży.</li>
    </ul>
    <p>Przy pompowaniu dętki odkręć nakrętkę zaworu i przymocuj głowicę pompki, upewniając się, że jest bezpiecznie zatrzaśnięta.</p>
    <h3>Krok 4: Pompowanie do pożądanego ciśnienia</h3>
    <p>Przy prawidłowo włączonej pompce wykonuj długie, zdecydowane ruchy. Użyj miernika ciśnienia, aby uzyskać pożądane PSI. Pamiętaj, że lekko niedopompowane opony są lepsze niż nadmiernie napompowane, jeśli zależy Ci na komforcie.</p>

      <img src="https://nextvelo-prod.b-cdn.net/blog_pictures/Screenshot%202024-11-21%20at%2020.29.22.png" alt="Jak napompować rower: Krótki przewodnik dla każdego rowerzysty" />

    <h3>Krok 5: Uszczelnij zawór i załóż nasadkę</h3>
    <p>Po osiągnięciu odpowiedniego ciśnienia zdejmij pompkę i zamknij zawór. W przypadku zaworu Presta dokręć mosiężną nakładkę, a następnie załóż nasadkę, aby zapobiec dostawaniu się brudu do zaworu.</p>
    <h2>Wnioski</h2>
    <p>Pompowanie opon może wydawać się drobnostką, ale jest niezbędne dla płynnej i przyjemnej jazdy. Dzięki prawidłowo napompowanym oponom zyskujesz większą kontrolę, prędkość i komfort na każdym rodzaju terenu.</p>
    <p>Gotowy na kolejną przejażdżkę? Sprawdź nasze wysokiej jakości używane akcesoria rowerowe w sklepie <a href="https://gearro.pl">Gearro</a>  - od pompek po zestawy naprawcze. Dzięki Gearro zawsze jesteś przygotowany na każdą przygodę!</p>
</article>
`;

export default { meta, content };
