import CoJestBezpieczniejszeEN from '../../articles/en/CoJestBezpieczniejsze.article.js';
import CoLatwiejszeNartyCzySnowboardEN from '../../articles/en/CoLatwiejszeNartyCzySnowboard.article.js';
import CoToJestSnowboardingEN from '../../articles/en/CoToJestSnowboarding.article.js';
import CoZabracNaNartyEN from '../../articles/en/CoZabracNaNarty.article.js';
import CzyKaskNaRowerJestObowiazkowyEN from '../../articles/en/CzyKaskNaRowerJestObowiazkowy.article.js';
import DopłatyDoRowerówElektrycznychEN from '../../articles/en/DopłatyDoRowerówElektrycznych.article.js';
import GdzieNaNartyEN from '../../articles/en/GdzieNaNarty.article.js';
import GdzieNaNartyWPolsceEN from '../../articles/en/GdzieNaNartyWPolsce.article.js';
import GdzieNaNartyZDziecmiEN from '../../articles/en/GdzieNaNartyZDziecmi.article.js';
import GdzieWyrzucicOponyRoweroweEN from '../../articles/en/GdzieWyrzucicOponyRowerowe.article.js';
import JakDobracNartyEN from '../../articles/en/JakDobracNarty.article.js';
import JakDobracNartyDoSwojegoWzrostuEN from '../../articles/en/JakDobracNartyDoSwojegoWzrostu.article.js';
import JakDobracRowerDoWzrostuEN from '../../articles/en/JakDobracRowerDoWzrostu.article.js';
import JakDobracSiodelkoRoweroweEN from '../../articles/en/JakDobracSiodelkoRowerowe.article.js';
import JakDobracSnowboardEN from '../../articles/en/JakDobracSnowboard.article.js';
import JakJezdzicNaSnowboardzieEN from '../../articles/en/JakJezdzicNaSnowboardzie.article.js';
import JakNapompowacRowerEN from '../../articles/en/JakNapompowacRower.article.js';
import JakPompowacOponyRoweroweEN from '../../articles/en/JakPompowacOponyRowerowe.article.js';
import JakPrzerobicRowerNaElektrycznyEN from '../../articles/en/JakPrzerobicRowerNaElektryczny.article.js';
import JakSieUbracNaNartyEN from '../../articles/en/JakSieUbracNaNarty.article.js';
import JakWycentrowacKoloRoweroweEN from '../../articles/en/JakWycentrowacKoloRowerowe.article.js';
import JakWyslacRowerKurieremEN from '../../articles/en/JakWyslacRowerKurierem.article.js';
import JakiGravelDo5000EN from '../../articles/en/JakiGravelDo5000.article.js';
import JakiRowerDla7LatkaEN from '../../articles/en/JakiRowerDla7Latka.article.js';
import JakiRowerElektrycznyJestDlaCiebieEN from '../../articles/en/JakiRowerElektrycznyJestDlaCiebie.article.js';
import JakiRowerKupicIn2025EN from '../../articles/en/JakiRowerKupicIn2025.article.js';
import OdpowiedniDlaCiebieRowerMtbEN from '../../articles/en/OdpowiedniDlaCiebieRowerMtb.article.js';
import RowerElektrycznyWskazowkiDotyczaceKonserwacjiEN from '../../articles/en/RowerElektrycznyWskazowkiDotyczaceKonserwacji.article.js';
import RowerGravelPorównaniePopularnychModeliEN from '../../articles/en/RowerGravelPorównaniePopularnychModeli.article.js';
import RowerStacjonarnyJakWybracNajlepszyEN from '../../articles/en/RowerStacjonarnyJakWybracNajlepszy.article.js';
import RowerSzosowyCanyonEN from '../../articles/en/RowerSzosowyCanyon.article.js';
import Top5RowerówEnduroEN from '../../articles/en/Top5RowerówEnduro.article.js';
import CoJestBezpieczniejszePL from '../../articles/pl/CoJestBezpieczniejsze.article.js';
import CoLatwiejszeNartyCzySnowboardPL from '../../articles/pl/CoLatwiejszeNartyCzySnowboard.article.js';
import CoToJestSnowboardingPL from '../../articles/pl/CoToJestSnowboarding.article.js';
import CoZabracNaNartyPL from '../../articles/pl/CoZabracNaNarty.article.js';
import CzyKaskNaRowerJestObowiazkowyPL from '../../articles/pl/CzyKaskNaRowerJestObowiazkowy.article.js';
import DopłatyDoRowerówElektrycznychPL from '../../articles/pl/DopłatyDoRowerówElektrycznych.article.js';
import GdzieNaNartyPL from '../../articles/pl/GdzieNaNarty.article.js';
import GdzieNaNartyWPolscePL from '../../articles/pl/GdzieNaNartyWPolsce.article.js';
import GdzieNaNartyZDziecmiPL from '../../articles/pl/GdzieNaNartyZDziecmi.article.js';
import GdzieWyrzucicOponyRowerowePL from '../../articles/pl/GdzieWyrzucicOponyRowerowe.article.js';
import JakDobracNartyPL from '../../articles/pl/JakDobracNarty.article.js';
import JakDobracNartyDoSwojegoWzrostuPL from '../../articles/pl/JakDobracNartyDoSwojegoWzrostu.article.js';
import JakDobracRowerDoWzrostuPL from '../../articles/pl/JakDobracRowerDoWzrostu.article.js';
import JakDobracSiodelkoRowerowePL from '../../articles/pl/JakDobracSiodelkoRowerowe.article.js';
import JakDobracSnowboardPL from '../../articles/pl/JakDobracSnowboard.article.js';
import JakJezdzicNaSnowboardziePL from '../../articles/pl/JakJezdzicNaSnowboardzie.article.js';
import JakNapompowacRowerPL from '../../articles/pl/JakNapompowacRower.article.js';
import JakPompowacOponyRowerowePL from '../../articles/pl/JakPompowacOponyRowerowe.article.js';
import JakPrzerobicRowerNaElektrycznyPL from '../../articles/pl/JakPrzerobicRowerNaElektryczny.article.js';
import JakSieUbracNaNartyPL from '../../articles/pl/JakSieUbracNaNarty.article.js';
import JakWycentrowacKoloRowerowePL from '../../articles/pl/JakWycentrowacKoloRowerowe.article.js';
import JakWyslacRowerKurieremPL from '../../articles/pl/JakWyslacRowerKurierem.article.js';
import JakiGravelDo5000ZlotyPL from '../../articles/pl/JakiGravelDo5000Zloty.article.js';
import JakiRowerDla7LatkaPL from '../../articles/pl/JakiRowerDla7Latka.article.js';
import JakiRowerElektrycznyJestDlaCiebiePL from '../../articles/pl/JakiRowerElektrycznyJestDlaCiebie.article.js';
import JakiRowerKupicIn2025PL from '../../articles/pl/JakiRowerKupicIn2025.article.js';
import OdpowiedniDlaCiebieRowerMtbPL from '../../articles/pl/OdpowiedniDlaCiebieRowerMtb.article.js';
import RowerElektrycznyWskazowkiDotyczaceKonserwacjiPL from '../../articles/pl/RowerElektrycznyWskazowkiDotyczaceKonserwacji.article.js';
import RowerGravelPorównaniePopularnychModeliPL from '../../articles/pl/RowerGravelPorównaniePopularnychModeli.article.js';
import RowerStacjonarnyJakWybracNajlepszyPL from '../../articles/pl/RowerStacjonarnyJakWybracNajlepszy.article.js';
import RowerSzosowyCanyonPL from '../../articles/pl/RowerSzosowyCanyon.article.js';
import Top5RowerówEnduroPL from '../../articles/pl/Top5RowerówEnduro.article.js';

const articles = [
  {
    slug: 'co-jest-bezpieczniejsze',
    content: CoJestBezpieczniejszeEN.content,
    meta: CoJestBezpieczniejszeEN.meta,
    language: 'en',
  },
  {
    slug: 'co-jest-bezpieczniejsze',
    content: CoJestBezpieczniejszePL.content,
    meta: CoJestBezpieczniejszePL.meta,
    language: 'pl',
  },
  {
    slug: 'co-latwiejsze-narty-czy-snowboard',
    content: CoLatwiejszeNartyCzySnowboardEN.content,
    meta: CoLatwiejszeNartyCzySnowboardEN.meta,
    language: 'en',
  },
  {
    slug: 'co-latwiejsze-narty-czy-snowboard',
    content: CoLatwiejszeNartyCzySnowboardPL.content,
    meta: CoLatwiejszeNartyCzySnowboardPL.meta,
    language: 'pl',
  },
  {
    slug: 'co-to-jest-snowboarding',
    content: CoToJestSnowboardingEN.content,
    meta: CoToJestSnowboardingEN.meta,
    language: 'en',
  },
  {
    slug: 'co-to-jest-snowboarding',
    content: CoToJestSnowboardingPL.content,
    meta: CoToJestSnowboardingPL.meta,
    language: 'pl',
  },
  {
    slug: 'co-zabrac-na-narty',
    content: CoZabracNaNartyEN.content,
    meta: CoZabracNaNartyEN.meta,
    language: 'en',
  },
  {
    slug: 'co-zabrac-na-narty',
    content: CoZabracNaNartyPL.content,
    meta: CoZabracNaNartyPL.meta,
    language: 'pl',
  },
  {
    slug: 'czy-kask-na-rower-jest-obowiazkowy',
    content: CzyKaskNaRowerJestObowiazkowyEN.content,
    meta: CzyKaskNaRowerJestObowiazkowyEN.meta,
    language: 'en',
  },
  {
    slug: 'czy-kask-na-rower-jest-obowiazkowy',
    content: CzyKaskNaRowerJestObowiazkowyPL.content,
    meta: CzyKaskNaRowerJestObowiazkowyPL.meta,
    language: 'pl',
  },
  {
    slug: 'dopłaty-do-rowerów-elektrycznych',
    content: DopłatyDoRowerówElektrycznychEN.content,
    meta: DopłatyDoRowerówElektrycznychEN.meta,
    language: 'en',
  },
  {
    slug: 'dopłaty-do-rowerów-elektrycznych',
    content: DopłatyDoRowerówElektrycznychPL.content,
    meta: DopłatyDoRowerówElektrycznychPL.meta,
    language: 'pl',
  },
  {
    slug: 'gdzie-na-narty',
    content: GdzieNaNartyEN.content,
    meta: GdzieNaNartyEN.meta,
    language: 'en',
  },
  {
    slug: 'gdzie-na-narty',
    content: GdzieNaNartyPL.content,
    meta: GdzieNaNartyPL.meta,
    language: 'pl',
  },
  {
    slug: 'gdzie-na-narty-wpolsce',
    content: GdzieNaNartyWPolsceEN.content,
    meta: GdzieNaNartyWPolsceEN.meta,
    language: 'en',
  },
  {
    slug: 'gdzie-na-narty-wpolsce',
    content: GdzieNaNartyWPolscePL.content,
    meta: GdzieNaNartyWPolscePL.meta,
    language: 'pl',
  },
  {
    slug: 'gdzie-na-narty-zdziecmi',
    content: GdzieNaNartyZDziecmiEN.content,
    meta: GdzieNaNartyZDziecmiEN.meta,
    language: 'en',
  },
  {
    slug: 'gdzie-na-narty-zdziecmi',
    content: GdzieNaNartyZDziecmiPL.content,
    meta: GdzieNaNartyZDziecmiPL.meta,
    language: 'pl',
  },
  {
    slug: 'gdzie-wyrzucic-opony-rowerowe',
    content: GdzieWyrzucicOponyRoweroweEN.content,
    meta: GdzieWyrzucicOponyRoweroweEN.meta,
    language: 'en',
  },
  {
    slug: 'gdzie-wyrzucic-opony-rowerowe',
    content: GdzieWyrzucicOponyRowerowePL.content,
    meta: GdzieWyrzucicOponyRowerowePL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-dobrac-narty',
    content: JakDobracNartyEN.content,
    meta: JakDobracNartyEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-dobrac-narty',
    content: JakDobracNartyPL.content,
    meta: JakDobracNartyPL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-dobrac-narty-do-swojego-wzrostu',
    content: JakDobracNartyDoSwojegoWzrostuEN.content,
    meta: JakDobracNartyDoSwojegoWzrostuEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-dobrac-narty-do-swojego-wzrostu',
    content: JakDobracNartyDoSwojegoWzrostuPL.content,
    meta: JakDobracNartyDoSwojegoWzrostuPL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-dobrac-rower-do-wzrostu',
    content: JakDobracRowerDoWzrostuEN.content,
    meta: JakDobracRowerDoWzrostuEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-dobrac-rower-do-wzrostu',
    content: JakDobracRowerDoWzrostuPL.content,
    meta: JakDobracRowerDoWzrostuPL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-dobrac-siodelko-rowerowe',
    content: JakDobracSiodelkoRoweroweEN.content,
    meta: JakDobracSiodelkoRoweroweEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-dobrac-siodelko-rowerowe',
    content: JakDobracSiodelkoRowerowePL.content,
    meta: JakDobracSiodelkoRowerowePL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-dobrac-snowboard',
    content: JakDobracSnowboardEN.content,
    meta: JakDobracSnowboardEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-dobrac-snowboard',
    content: JakDobracSnowboardPL.content,
    meta: JakDobracSnowboardPL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-jezdzic-na-snowboardzie',
    content: JakJezdzicNaSnowboardzieEN.content,
    meta: JakJezdzicNaSnowboardzieEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-jezdzic-na-snowboardzie',
    content: JakJezdzicNaSnowboardziePL.content,
    meta: JakJezdzicNaSnowboardziePL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-napompowac-rower',
    content: JakNapompowacRowerEN.content,
    meta: JakNapompowacRowerEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-napompowac-rower',
    content: JakNapompowacRowerPL.content,
    meta: JakNapompowacRowerPL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-pompowac-opony-rowerowe',
    content: JakPompowacOponyRoweroweEN.content,
    meta: JakPompowacOponyRoweroweEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-pompowac-opony-rowerowe',
    content: JakPompowacOponyRowerowePL.content,
    meta: JakPompowacOponyRowerowePL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-przerobic-rower-na-elektryczny',
    content: JakPrzerobicRowerNaElektrycznyEN.content,
    meta: JakPrzerobicRowerNaElektrycznyEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-przerobic-rower-na-elektryczny',
    content: JakPrzerobicRowerNaElektrycznyPL.content,
    meta: JakPrzerobicRowerNaElektrycznyPL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-sie-ubrac-na-narty',
    content: JakSieUbracNaNartyEN.content,
    meta: JakSieUbracNaNartyEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-sie-ubrac-na-narty',
    content: JakSieUbracNaNartyPL.content,
    meta: JakSieUbracNaNartyPL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-wycentrowac-kolo-rowerowe',
    content: JakWycentrowacKoloRoweroweEN.content,
    meta: JakWycentrowacKoloRoweroweEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-wycentrowac-kolo-rowerowe',
    content: JakWycentrowacKoloRowerowePL.content,
    meta: JakWycentrowacKoloRowerowePL.meta,
    language: 'pl',
  },
  {
    slug: 'jak-wyslac-rower-kurierem',
    content: JakWyslacRowerKurieremEN.content,
    meta: JakWyslacRowerKurieremEN.meta,
    language: 'en',
  },
  {
    slug: 'jak-wyslac-rower-kurierem',
    content: JakWyslacRowerKurieremPL.content,
    meta: JakWyslacRowerKurieremPL.meta,
    language: 'pl',
  },
  {
    slug: 'jaki-gravel-do5000',
    content: JakiGravelDo5000EN.content,
    meta: JakiGravelDo5000EN.meta,
    language: 'en',
  },
  {
    slug: 'jaki-gravel-do5000zloty',
    content: JakiGravelDo5000ZlotyPL.content,
    meta: JakiGravelDo5000ZlotyPL.meta,
    language: 'pl',
  },
  {
    slug: 'jaki-rower-dla7latka',
    content: JakiRowerDla7LatkaEN.content,
    meta: JakiRowerDla7LatkaEN.meta,
    language: 'en',
  },
  {
    slug: 'jaki-rower-dla7latka',
    content: JakiRowerDla7LatkaPL.content,
    meta: JakiRowerDla7LatkaPL.meta,
    language: 'pl',
  },
  {
    slug: 'jaki-rower-elektryczny-jest-dla-ciebie',
    content: JakiRowerElektrycznyJestDlaCiebieEN.content,
    meta: JakiRowerElektrycznyJestDlaCiebieEN.meta,
    language: 'en',
  },
  {
    slug: 'jaki-rower-elektryczny-jest-dla-ciebie',
    content: JakiRowerElektrycznyJestDlaCiebiePL.content,
    meta: JakiRowerElektrycznyJestDlaCiebiePL.meta,
    language: 'pl',
  },
  {
    slug: 'jaki-rower-kupic-in2025',
    content: JakiRowerKupicIn2025EN.content,
    meta: JakiRowerKupicIn2025EN.meta,
    language: 'en',
  },
  {
    slug: 'jaki-rower-kupic-in2025',
    content: JakiRowerKupicIn2025PL.content,
    meta: JakiRowerKupicIn2025PL.meta,
    language: 'pl',
  },
  {
    slug: 'odpowiedni-dla-ciebie-rower-mtb',
    content: OdpowiedniDlaCiebieRowerMtbEN.content,
    meta: OdpowiedniDlaCiebieRowerMtbEN.meta,
    language: 'en',
  },
  {
    slug: 'odpowiedni-dla-ciebie-rower-mtb',
    content: OdpowiedniDlaCiebieRowerMtbPL.content,
    meta: OdpowiedniDlaCiebieRowerMtbPL.meta,
    language: 'pl',
  },
  {
    slug: 'rower-elektryczny-wskazowki-dotyczace-konserwacji',
    content: RowerElektrycznyWskazowkiDotyczaceKonserwacjiEN.content,
    meta: RowerElektrycznyWskazowkiDotyczaceKonserwacjiEN.meta,
    language: 'en',
  },
  {
    slug: 'rower-elektryczny-wskazowki-dotyczace-konserwacji',
    content: RowerElektrycznyWskazowkiDotyczaceKonserwacjiPL.content,
    meta: RowerElektrycznyWskazowkiDotyczaceKonserwacjiPL.meta,
    language: 'pl',
  },
  {
    slug: 'rower-gravel-porównanie-popularnych-modeli',
    content: RowerGravelPorównaniePopularnychModeliEN.content,
    meta: RowerGravelPorównaniePopularnychModeliEN.meta,
    language: 'en',
  },
  {
    slug: 'rower-gravel-porównanie-popularnych-modeli',
    content: RowerGravelPorównaniePopularnychModeliPL.content,
    meta: RowerGravelPorównaniePopularnychModeliPL.meta,
    language: 'pl',
  },
  {
    slug: 'rower-stacjonarny-jak-wybrac-najlepszy',
    content: RowerStacjonarnyJakWybracNajlepszyEN.content,
    meta: RowerStacjonarnyJakWybracNajlepszyEN.meta,
    language: 'en',
  },
  {
    slug: 'rower-stacjonarny-jak-wybrac-najlepszy',
    content: RowerStacjonarnyJakWybracNajlepszyPL.content,
    meta: RowerStacjonarnyJakWybracNajlepszyPL.meta,
    language: 'pl',
  },
  {
    slug: 'rower-szosowy-canyon',
    content: RowerSzosowyCanyonEN.content,
    meta: RowerSzosowyCanyonEN.meta,
    language: 'en',
  },
  {
    slug: 'rower-szosowy-canyon',
    content: RowerSzosowyCanyonPL.content,
    meta: RowerSzosowyCanyonPL.meta,
    language: 'pl',
  },
  {
    slug: 'top5rowerów-enduro',
    content: Top5RowerówEnduroEN.content,
    meta: Top5RowerówEnduroEN.meta,
    language: 'en',
  },
  {
    slug: 'top5rowerów-enduro',
    content: Top5RowerówEnduroPL.content,
    meta: Top5RowerówEnduroPL.meta,
    language: 'pl',
  },
];

export { articles };